
@font-face {
  font-family: "poppins-light";
  src: url("font/Poppins/Poppins-Light.ttf");

  }
@font-face {
  font-family: "poppins-regular";
  src: url("font/Poppins/Poppins-Regular.ttf");

  }
@font-face {
  font-family:"Mechsuit";
  src: url("font/Mechsuit.otf");
}  
@font-face {
  font-family: "poppins-semibold";
  src: url("font/Poppins/Poppins-SemiBold.ttf");

  }
@font-face {
  font-family: "poppins-medium";
  src: url("font/Poppins/Poppins-Medium.ttf");

  }
@font-face {
  font-family: "poppins-bold";
  src: url("font/Poppins/Poppins-Bold.ttf");

  }
@font-face {
  font-family: "abb";
  src: url("font/abb/ABBvoice.ttf");

  }
  .poppins-light{
    font-family: "poppins-light";
  }
  .poppins{
    font-family: "poppins-regular";
    
  }
  .poppins-semibold{
    font-family: "poppins-semibold" !important;
    
  }
  .poppins-bold{
    font-family: "poppins-bold" !important;
    
  }
  .poppins-medium{
font-family: "poppins-medium";
  }
  .abb-voice{
font-family: "abb";
  }

  /* font end */
body {
  font-family: Arial, sans-serif;
  overflow-x: hidden;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
 a{
  text-decoration: none;
  color: white;
 }


::-webkit-scrollbar{
  display: none;
}


.roboai{
  height: 2.7rem;
  width: 10.9rem;
  font-weight: 700;
}
.ffroboai{
  font-family:"poppins-bold";
  font-size: 1rem;
  cursor: pointer;
}
.ffroboai:hover{
  color: white;
}
.gap4{
  gap:2.0rem;
}
 .rectangle {
  margin-top: 7rem;
  padding: 5rem 2rem;
  /* background-color: #023f7c; */
  background-image: url(./images/bg.jpg);
  background-repeat: no-repeat;
  background-size: 100vw;
  position: relative;
  width: 80vw; /* Adjust width as needed */
  height: 40vh; /* Adjust height as needed */
  overflow: hidden;
  justify-content: space-between;
  /* display: flex; */

}

.background-image {
  
  border-radius: 10px; /* Adjust border radius as needed */
  width: 15vw;
  height: 25vh;
  object-fit: cover;
  margin-top: 30px;
  position: absolute;
  z-index: 99;
  left: 15rem;
}
.float-right{
  float: right;
}
.border-w{
  border: 1px solid;
  border-color: white;
}
.border-w:hover {
  border-color: #c4c4c4 !important;
}
.content-blog{
  color: white;
  width: 100%;
  padding-right: 3rem;
}

.paragraph-blog{
  font-size:1.2rem 
}
.content-blog h3{
  font-size: 2rem;
}
.yellow-button{
  color: black;
  font-weight: 600;
}
.border-round{
  border-radius: 0.5rem;
}
.fw-topics{
  font-weight: 700;
}

.eventtabcard .card-body{
  height: 38vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.foota:hover{
  color: #c4c4c4;

}
.free{
  font-size: 20px !important;
  color:red
}
.free-text{
  text-align: center;
  z-index: 2;
  font-size: 20px !important;
  color:red !important

}
.card-label .card-body{
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  height: 25vw;

}
.evt-btn:hover{
  background-color: black !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* color */

:root {
  --Primary-color: #023882;
  --secondary-color: #c4c4c4;
  --Primary-color2: #0b63d0;
  --card-bg: #f0f0f0;
  --title-color: -webkit-linear-gradient(#0071df, #022251);
}
.my-secondary-color {
  color: var(--secondary-color);
}
.my-secondary-color1{
  color: #555555;
}
.my-secondary-bg {
  background: var(--card-bg);
}
.bg-black {
  background-color: black;
  border: none;
}
.card-color {
  background-color: var(--card-bg);
}
.title-color {
  font-size: 50px;
  background: var(--title-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* card hover */
.card-hover {
  transition: transform 0.4s;
  -webkit-transition: transform 0.4s;
  -moz-transition: transform 0.4s;
  -ms-transition: transform 0.4s;
  -o-transition: transform 0.4s;
}
.corcard .card-body{
  height: 52vh;
 }
.card-hover:hover {
  /* background-color: var(--Primary-color); */
  background-image: url("images/banner/cardhover.png");
  background-position: center;

  height: calc(100% + 30px);
}

.card-hover:hover .hover-title {
  color: white;
}
.card-hover:hover .bg-black {
  background-color: #0275d8 !important;
}

/* tabs */
.bg-banner {
  background-image: url("images/banner/bottom.png");
}
.MuiTabs-flexContainer {
  justify-content: center;
}

/* rough */
.bg-g{

  background: linear-gradient(180deg, transparent 29%, rgba(13,13,13,1) 51%);
position: absolute;
    height: 100%;
    width: 100%;
}
.r-35{
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}
.black-card-text{
  color: white;
  z-index: 1;
  position: relative;
}

/* three card */

/* .bgcard1{
  background-image: url('images/card1.png');
  height: 33%;
  background-position: center;
  background-size: 100%;
}
.bgcard2{
  background-image: url('images/card2.png');
}
.bgcard3{
  background-image: url('images/card3.png');
} */

.relative {
  position: relative;
}

.position {
  position: absolute;
  top: 20px;
  left: 35px;
}
.position1 {
  position: absolute;
  color: white;
  bottom: 20px;
  left: 35px;
}
.f-10 {
  font-size: 13px;
}

/* pathers */
.text-color {
  background: var(--title-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.partner-text {
  display: flex;
  align-items: center;
}

.p-text {
  letter-spacing: 1px;
  color: var(--Primary-color2);
  text-align: center;
}

/* internship */

.position-3 {
  position: absolute;
  top: 0;
}

.position-3 div > img {
  width: 100%;
}

.card-bg-img {
  background-image: url("images/banner/cardhover.png");
  background-position: center;
}

.mobile-intern img {
  width: 100%;
}
.mobile-intern > div {
  height: 170px;
  display: flex;
  align-items: center;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.primary-text {
  color: var(--Primary-color);
}
/* two column product */
.two-colum-product {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.l-1 {
  letter-spacing: 1px;
  text-align: justify;
}
.border-p {
  border: 1px solid;
}
.rotate-225 {
  transform: rotate(225deg);
}
.radius-15 {
  border-radius: 15px;
}
.radius-7{
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.two-card-column:nth-child(even) > .column-order-text {
  text-align: right;
}
.two-card-column1:nth-child(odd) > .column-order-text {
  text-align: right;
}


/* past events */

.card-gradient {
  /*background-color: rgb(0, 10, 10);
  */background-image: url("images/product/industrial.png");
  background-size: cover;
  height: 400px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.card-gradient * {
  color: white;
}

.card-gradient-body {
  position: absolute;
  bottom: 0;
}


/* tabs */
.MuiTab-root{
  font-weight: 300 !important;
}
.Mui-selected{
  font-weight: bold !important;
}

/* blog */
.blog-height{
background-size: cover;
  
  min-height: 500px;
}
.height-50{
  height: 50px;
  border-radius: 7px;
}

.card-div-position{
  position: absolute;
  bottom: 0;
  margin: 25px;
  left: 0;
}

/* banner */


.home-bg-banner{
  background-image: url('images/banner/main.png');
  height: 100vh;
  width: 100wh;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-text{
  
  font-size: 90px;
  font-weight: 700;
  text-shadow: 1px 1px 7px cornflowerblue;



}
.banner-top-padding{
  padding-top:120px;
}

.btn-color{
  border:1px solid white;
  color:white;
}

.btn-hover {
  position: relative;
  overflow: hidden;
}

.btn-hover i {
  transition: transform 0.3s ease;
}

.btn-hover:hover i {
  transform: translateX(5px);
}

.btn-hover:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* navbar */

.nav-absulote{
  width: 100%;
  left: 0;
  top: 0;
  background: transparent;
  position: absolute;
}


/* slider */





.slick-slide > div {
  margin: 0 10px;
}

.slick-list {
  margin: 0 -10px;
}
.slick-slider {
  overflow: hidden;
}
/* single product page */

.single-p-bg{
  background-image: url('images/banner/main.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.three-rem{
  font-size: 3.5rem;
}
.font-17{
  font-size:17px;
}

/* add to cart */
.add-to-cart{
  position: absolute;
  width: 100%;
  margin-top: 15px;
}
.pb-70{
  padding-bottom: 70px;
}
.pb-80{
  padding-bottom: 80px;
}


/* chapter tab */
.tabs {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.tabs label {
  order: 1;
  display: block;
  padding: 0.6rem 2rem;
  margin-right: 0.2rem;
  cursor: pointer;
  background: var(--card-bg);
  color: black;
  font-weight: 500;
  transition: background ease 0.2s;
  border: 0.8px solid var(--bs-border-color-translucent);
}

.tabs .tab {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem;
  background: #fff;
}

.tab h1,
.tab p {
  color: #444444;
  font-weight: initial;
}

.tabs input[type="radio"] {
  display: none;
}

.tabs input[type="radio"]:checked+label .a {
  display: none;

}
.m{
  display: none;
}
.tabs input[type="radio"]:checked+label .m {
  display: initial;

}

.tabs input[type="radio"]:checked+label+.tab {
  display: block;
}

.tabs .tab,
.tabs label {
  order: initial;
}

.link{
  text-decoration: none;
}

/* nav */
.hover-nav:hover{
font-weight: 700;
/* border-bottom: 4px solid var(--Primary-color2); */
  
}
.active:after{
  content: '';
  height: 4px;
  width: 100%;
  background-color: var(--Primary-color2);
  display: block;
 
  bottom: 0;
  /* border-bottom: 4px solid var(--Primary-color2); */
    
  }
.active{
  font-weight: bold;
  /* border-bottom: 4px solid var(--Primary-color2); */
}
/* .card-label1 >div:first-child>div::after{
 
  content: "New";
  display: block;
  position: absolute;
  left: -33px;
  top: 16px;
  background: aliceblue;
  text-align: center;
  transform: rotate(-45deg);
  padding: 2px 33px;
  transform-origin: center;

  
} */
/* .card-label1 >div:first-child>div:first-child >div{
  background: #b2c3d2;
  position: absolute;
  height: 20px;
  top: -9px;
  left: 33px;
  width: 23px;
  z-index: -1;
  transform: rotate(33deg);
}
.card-label1>div:first-child>div::before{
 
  content: "";
    display: block;
    position: absolute;
    left: -9px;
    top: 65px;
    height: 14px;
    background: #b2c3d2;
    text-align: center;
    transform: rotate(20deg);
    padding: 2px 14px;
    transform-origin: center;
    z-index: -1;
} */
/* end nav */
.logo{
  height: 55px;
}
.img-absolute{
  right: 133px;
  bottom: 59px;
  width: 93%;
  position: absolute;
}
/* .img-absolute{
  right: 70px;
  bottom: 33px;
  width: 110%;
  position: absolute;
} */
.animation-div{
  right: -61px;
  bottom: -39px;
  position: absolute;
}
.circle-width{
  width: 95%;
}
.arrow-absolute{
    position: absolute;
    bottom: 15px;
    left: 50%;
    -webkit-animation: action 2s infinite  alternate;
    animation: action 0.5s infinite  alternate;
    transition: transform 4s ease-in-out;
    -webkit-transition: transform 4s ease-in-out;
    -moz-transition: transform 4s ease-in-out;
    -ms-transition: transform 4s ease-in-out;
    -o-transition: transform 4s ease-in-out;
    cursor: pointer;
}

@-webkit-keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}


@keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
@media (max-width: 45em) {

  .tabs .tab,
  .tabs label {
      order: initial;
  }

  .tabs label {
      width: 100%;
      margin-right: 0;
      margin-top: 0.2rem;
  }
}
.border-50{
  border-radius: 35px;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  -ms-border-radius: 35px;
  -o-border-radius: 35px;
}
/* end chapter tab */
/* review */
.review-hover:hover{
  background-color: var(--Primary-color2) !important;
}
.review-hover:hover>*{
  color:white !important
}

.footer-bg-banner{
  background-image: url('images/banner/main.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding: 1rem 2rem;
  
  
}
.footer-flex{
  display: flex;

}
.footer-flex-container{
  width: 65%;
}
.font-size-3{
  font-size: 1.2rem;
  margin-left: -1.2px;
}
.my-secondary-color2{
  color:#e8e8e8;
}
.mantor-img{
  height: 200px;
  width:200px;
  object-fit: cover;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.reserved{
  text-decoration: none;
  color:black;
}

/* top btn p */
.btm-div{
  position: absolute;
    bottom: 0;
}
/* media */
.faq-section {
  max-width: 90vw;
  margin: 0 auto;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.section-title {
  text-align: center;
  font-size: 24px;
  margin-bottom: 30px;
}

.faq-item {
  margin-bottom: 20px;
}

.question {
  color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-image: url(./images/bg.jpg);
  background-size: 40vw;
  
  padding: 10px;
  border-radius: 5px;
}



.question-text {
  font-size: 18px;
  margin: 0;
  flex-grow: 1;
}

.toggle-icon {
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
}



.answer {
  color: white;
  /* display: none; */
  padding: 10px;
  background: rgb(58,69,162);
background: linear-gradient(138deg, rgba(58,69,162,1) 4%, rgba(34,87,132,1) 43%, rgba(54,53,148,1) 100%);
  border-radius: 5px;
  position: absolute;
  width: 40vw;
}

.answer-text {
  font-size: 16px;
  margin: 0;
}

.answer.visible {
  display: block;
}



@media only screen and (max-width: 576px) {
  .answer{
    width: 80vw;
  }
  .faq-section{
    grid-template-columns:repeat(1, 1fr); ;
  }
.footer-contact p{
  font-size: 0.7rem;

}
.footer-bg-banner{
  padding: 0;
}
  .background-image{
    border-radius: 10px;
    width: 48vw;
    height: 21vh;
    object-fit: cover;
    margin-top: 50px;
    position: absolute;
    z-index: 99;
    left: 6.5rem;
  }
  .content-blog{
    padding-right: 0px;
    padding-top: 4rem;
  }
  .content-blog p{
    font-size: 0.8rem;
  }
  .paragraph-blog{
    font-size: 0.9rem;
  }
  .content-blog h3{
    font-size: 1.2rem;
  }
  .text-s-c{
    text-align: center!important;
  }
  .partner-text h4 {
    text-align: center;
  }
  .MuiTabs-flexContainer{
    margin-left: 140px;
  }
  .card-div-position{
    position: static;
    margin: 15px 0px;
  }
  .blog-4line{
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 5.6em;
  }
  .blog-height{
    min-height: inherit;
    padding: 15px;
  }
  .css-19kzrtu{
    padding: 24px 0px !important;
  }


  .main-text{
   
    font-size: 47px;
    font-weight: 700;
    margin: auto;
    width: min-content;
  
  }
  .banner-top-padding{
    padding-top:90px;
  }
  .main-h2{
    font-size: 18px;
    font-weight: 700;
    text-align: center;
  }
  .main-p{
    display: none;
  }

  .bg-g {
    background: linear-gradient(180deg, transparent 29%, rgba(13,13,13,1) 40%);
   
}
.animation-div {
  right: 0px;
  bottom: -21px;
  position: static;
}
.img-absolute {
  right: 92px;
  bottom: 16px;
  position: static;
 
}
.circle-width {
  width: 85%;
}
.mt-20{
  margin-top: 110px !important;
}
.circle-width{
  display: none;
}
.btn2{
  justify-content: center;

}
.product-img{
  display: none;
}

/* why us */


}

@media only screen and (min-width: 768px) {
  
  .two-card-column:nth-child(even) > .column-order-1 {
    order: 1;
  }
  .two-card-column1:nth-child(odd) > .column-order-1 {
    order: 1;
  }
  .white-768{
    color:white !important;
  }
  .pd-event{
    padding:0 4.7rem !important;
  }
  .dropdown-menu[data-bs-popper] {
   
    left: -28px;
  
}
}

@media only screen and (min-width: 992px) {
.top-btn-p{
  padding: 9px 30px;
}

}
@media only screen and (min-width: 577px) and (max-width: 991px)  {
.main-text{
  font-size: 72px !important;
}
.main-h2{
  font-size: 22px;
}

.animation-div {
  right: -114px;
  bottom: 18px;

}
.img-absolute {
  right: 120px;
  bottom: 51px;
  width: 75%;
  left: -94px;
  
}
.circle-width {
  width: 70%;
}

}


.absolute-b{
  position: absolute;
  bottom: 0;
}
.rounded-12{
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}
.fa-1-8{
  font-size: 1.8em;
}

.punjab{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 12rem;
}

.govt{
  right: 25px;
    position: absolute;
    bottom: 20px;
    width: 5rem;
}

.product-img{
  top: 70px;
    position: absolute;
    height: 100%;
    right: 0;
    width: 100%;
}
.p-img{
  width: 79%;
  height: calc(100% - 70px);
}
.text-right{
  text-align: right;
}
nav{
  z-index: 1;
}
/* why us page */
.whyus-text{
  
    font-size: 75px;
    font-weight: 700;
    text-shadow: 1px 1px 7px cornflowerblue;

}

.ptb-135{
  padding: 135px 0px;
}
.ptb-whyus{
  padding: 110px 0px;
}
.bgwhyus{
 
    background: url('images/whyus/left.png'), url('images/whyus/right.png') ,url('images/banner/whyusmain.jpg') ;
    
    background-repeat: no-repeat,no-repeat,no-repeat;
    background-size: 456px,80%,cover;
    background-position: -200px -250px,500px -200px,center center;
    
  
}
.color-gray{
  color:#555555;
}
.bg-gray{
  background-color:#555555;
}
.border-2px{
  border-bottom: 2px solid white;
}
.border-2px-gray{
  border-bottom: 2px solid #555555;
}
.testimonial-text{
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  color:white;

}
.w-100px{
  width:40px;
}
.whyus-card-bg{
  background: url('images/whyus/bgleft.png'), url('images/whyus/bgright.png') ;
    
    background-repeat: no-repeat,no-repeat;
    background-size: 300px,260px;
    background-position: -40px 0px,101.5% 0px;
}

.corporatebg{
  background: url('images/corporate/bgleft.png') ,url('images/corporate/2card.png') , url('images/corporate/bgright.png');
    
  background-repeat: no-repeat,no-repeat,no-repeat;
  background-size: 50%,70%,90%;
  background-position: -420px -60px,335px -10px,600px -300px;
  

}
.corporate_color{
  color:#2e4e6d;
}
.corporate_bg{
  background-color:#2e4e6d;
}
.bdr-2{
  border-top: 2px solid #555555;
  border-bottom: 2px solid #555555;
}
.font-8rem{
  font-size: 7rem !important;
  margin: 0;
}
.lh-45{
  line-height: 45px;
}
.max-content{
  width: max-content;
  margin: auto;
}
.techbg{
  background: url('images/corporate/techleft.png'), url('images/corporate/techright.png');
    
  background-repeat: no-repeat,no-repeat,no-repeat;
  background-size: 30%,31%;
  background-position: -55px 0px,104% 20px;
}
.cardbg4{
  background-color:#2e4e6d;
  opacity: 0.8;
  position: absolute;
  top: 50px;
  width: 100%;
}
.hbg{
  background-image: url('images/corporate/hbg.png');
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
 
}
.mesh{
  background-image: url('images/corporate/mesh.png');
  background-size: 130%;
  background-position: center;
  background-repeat: no-repeat; 
}

#root > section:nth-child(7) > div:nth-child(2) > div > div,
#root > section.acardemic > div:nth-child(2) > div > div,#root > section.mesh > div:nth-child(2) > div > div
{
  padding-bottom: 50px !important;
}
.countbg{
  background:rgba(255,255,255, 0.8);

}
@media only screen and (max-width: 576px) {
  .whyus-text{
    font-size: 40px;
    font-weight: 700;
    margin: auto;
  }
  .eventtabcard .card-body {
    height: 40vh;
}
.card-label .card-body{
  height: 75vw;

}


  .whyus-card-bg{
    background: url('images/whyus/bgleft.png'), url('images/whyus/bgright.png') ;
      
      background-repeat: no-repeat,no-repeat;
      background-size: 100px,160px;
      background-position: -40px 0px,101.5% 0px;
  }
  
  }


  .fs-24{
    font-size: 24px !important;
  }
  .fs-14{
    font-size: 14px !important;
  }







  @media only screen and (width: 1280px) and (min-height: 587px) {
   .banner-top-padding{
    padding-top: 90px;
   }
   .main-text{
    font-size: 80px;
   }
   h2{
    font-size: 1.8rem;
   }
   .background-image{
    left:10rem;
   }
   .answer{
    width: 41vw;
   }
   .corcard .card-body{
    height: 65vh;
   }
   .eventtabcard .card-body {
    height: 50vh;
}
.card-label .card-body{
  height: 35vw;

}
  }