.active:after{
    content: '';
    height: 4px;
    width: 0%;
    background-color: none !important;
    display: block;
   
    bottom: 0;
    /* border-bottom: 4px solid var(--Primary-color2); */
      
    }


    .nav-pills .nav-link.active{
        background: url('images/banner/main.png');
      
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    